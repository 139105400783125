<template>
	<div class="404">
		<div class="container-fluid">
			<div class="row" style="margin-top: 15%; margin-bottom: 15%;">
				<div class="col"></div>
				<div class="col-sm-5">
					<h2>Hups... Jotain meni pieleen. Palaa etusivulle 
						<a href="/" type="button" class="btn btn-danger btn-t btn-danger-t">täältä!</a>
					</h2>
				</div>				
				<div class="col"></div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
name: '404'
}
</script>

<style type="text/css">

</style>
